<template lang="pug">
div
  v-data-table(
    :headers="headers"
    :items="dataList"
    :items-per-page="-1"
    :loading="loading"
    loading-text="資料載入中..."
    hide-default-footer
  )
    template(v-slot:item.reservedSeat="{ item }")
      span {{ item.reservedSeat | reservedSeat }}
    template(v-slot:item.actions="{ item }")
      v-btn(
        @click="edit(item)"
        small
        outlined
        color="teal"
        class="mx-1 px-0 text-button") 編輯
      v-btn(
        @click="handleCheckDelete(item)"
        small
        dark
        color="red darken-4"
        class="mx-1 px-0 text-button") 刪除
      v-btn(
        @click="sendSMS(item)"
        small
        color="indigo"
        dark
        class="mx-1 text-button") 寄簡訊
  v-dialog(
    v-model="deleteConfirmDialog"
    width="500"
  )
    v-card
      v-card-text(class="py-3 text-h5") 輸入 delete 確定刪除
      v-card-text
        v-text-field(
          v-model="deleteConfirm" 
          placeholder="delete"
          hide-details
        )
      v-card-actions
        v-spacer
        v-btn(
          text
          @click="deleteConfirmDialog = false") 取消
        v-btn(
          depressed
          color="mx-1 red darken-4"
          :dark="deleteConfirm === 'delete'"
          :disabled="deleteConfirm !== 'delete'"
          @click="deleteEditItem") 確認刪除
</template>
<script>
import editGroup from "@/components/common/editGroup";
export default {
  name: "outerSearchTable",
  props: {
    loading: {
      type: Boolean,
    },
    dataList: {
      type: Array,
    },
  },
  components: {
    editGroup,
  },
  data() {
    return {
      headers: [
        {
          text: "填表時間",
          align: "center",
          sortable: false,
          value: "registrationDate",
        },
        {
          text: "匯款日期",
          align: "center",
          sortable: false,
          value: "remittanceDate",
        },
        {
          text: "帳號末5碼",
          align: "center",
          sortable: false,
          value: "account5",
        },
        {
          text: "所屬教會",
          align: "center",
          sortable: false,
          value: "church",
        },
        {
          text: "負責人",
          align: "center",
          sortable: false,
          value: "responsiblePerson",
        },
        {
          text: "手機",
          align: "center",
          sortable: false,
          value: "phone",
        },
        {
          text: "教會職稱",
          align: "center",
          sortable: false,
          value: "jobTitle",
        },
        {
          text: "報名人數",
          align: "center",
          sortable: false,
          value: "registrationNumber",
        },
        {
          text: "匯款試算",
          align: "center",
          sortable: false,
          value: "amount",
        },
        {
          text: "確認狀態",
          align: "center",
          sortable: false,
          value: "reservedSeat",
        },
        {
          text: "簡訊狀態",
          align: "center",
          sortable: false,
          value: "smsText",
        },
        { text: "", align: "center", value: "actions", sortable: false },
      ],
      editMode: null,
      editingItem: {},
      deleteConfirmDialog: false,
      deleteConfirm: "",
    };
  },
  watch: {
    deleteConfirmDialog(val) {
      if (!val) {
        this.deleteConfirm = ""
      }
    }
  },
  methods: {
    edit(item) {
      this.editMode = "edit";
      this.editingItem = item;
      this.$emit('edit', item)
    },
    sendSMS(item) {
      this.$emit('sendSMS', item)
    },
    handleCheckDelete(item) {
      this.editingItem = item;
      this.deleteConfirmDialog = true;
    },
    deleteEditItem() {
      this.$emit("delete", this.editingItem);
      this.deleteConfirmDialog = false;
      this.editingItem = {};
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  padding: 0;
}

.edit-component {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 100px;
}
.edit-date-input {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 180px;
}
.checkbox {
  width: fit-content;
  margin: 0 auto;
}
</style>
