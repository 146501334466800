<template lang="pug">
v-card(
  class="pa-4"
  outlined
)
  v-form(@submit.prevent="submit")
    v-row
      v-col(cols="6" md="3")
        v-text-field(
          v-model="searchPayload.responsiblePerson" 
          label="負責人"
          hide-details
        )
      v-col(cols="6" md="3")
        v-text-field(
          v-model="searchPayload.phone" 
          label="負責人手機"
          hide-details
        )
      v-col(class="d-flex justify-space-between")
        div
          v-btn(
            @click.stop="handleSearch"
            type="submit"
            class="ma-2 font-weight-bold text-button" dark color="primary")
            v-icon(dark) mdi-magnify
            span(class="font-weight-bold") 搜尋
          v-btn(
            @click.stop="clear"
            class="ma-2 font-weight-bold text-button")
            v-icon mdi-broom
            span(class="font-weight-bold") 全部
          v-btn(
            :loading="loading"
            @click.stop="handleExport"
            class="ma-2 font-weight-bold text-button" color="primary")
            v-icon mdi-table-arrow-right
            span(class="font-weight-bold") 資料匯出
        div
          v-btn(
            @click.stop="$emit('add')"
            class="ma-2 ml-5 font-weight-bold text-button" color="success")
            v-icon mdi-plus
            span(class="font-weight-bold") 新增
</template>

<script>
import GroupComponent from "@/components/common/group";
export default {
  name: "outerSearchForm",
  components: {
    GroupComponent,
  },
  props: {
    addMode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchPayload: {
        responsiblePerson: "",
        phone: ""
      },
    };
  },
  methods: {
    submit() {
      this.$emit("search", this.searchPayload);
    },
    clear() {
      this.searchPayload = {
        responsiblePerson: "",
        phone: ""
      };
      this.submit();
    },
    handleExport() {
      this.$emit("export", this.searchPayload);
    },
  },
};
</script>
