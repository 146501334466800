<template lang="pug">
v-card(class="px-5 pb-5")
  v-card-title(class="px-0")
    span(class="text-h5 font-weight-bold") {{ detailMode === 'edit' ? '編輯' : '新增' }}
  v-form(@submit.prevent="submit" 
    ref="form"
    v-model="valid"
    lazy-validation)
    v-row
      v-col(cols="6")
        v-text-field(
          v-model="outerData.church" 
          label="所屬教會"
          :rules="rules"
          hide-details
        )
      v-col(cols="6")
        v-radio-group(
          v-model="outerData.gender"
          row
          hide-details
        )
          template(v-slot:label)
            span 性別
          v-radio(label="男" value="男")
          v-radio(label="女" value="女")
      v-col(cols="6")
        v-text-field(
          v-model="outerData.responsiblePerson" 
          label="負責人"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="outerData.phone" 
          label="負責人手機"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="outerData.email" 
          label="Email"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="outerData.tel" 
          label="教會電話"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
            v-model="outerData.jobTitle" 
            label="教會職稱"
            hide-details
          )
      v-col(cols="6")
        v-text-field(
          v-model="outerData.registrationNumber" 
          label="團體報名人數"
          hide-details
        )
      v-col(cols="12")
        v-textarea(
          v-model="outerData.registrationNameList" 
          label="團體成員姓名"
          hide-details
          rows="3"
        )
      v-col(cols="6")
        v-text-field(
          v-model="outerData.account5" 
          label="匯款帳號末5碼"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
            v-model="outerData.remittanceDate" 
            label="匯款日期"
            hide-details
            type="date"
          )
      v-col(cols="7")
        v-checkbox(
          v-model="outerData.reservedSeat" 
          label="確認已付款"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
            v-model="outerData.uniformNo" 
            label="統一編號"
            hide-details
          )
      v-col(cols="6")
        v-text-field(
          v-model="outerData.uniformTitle" 
          label="統編抬頭"
          hide-details
        )
    v-row
      v-col(class="d-flex justify-center")
          v-btn(
            type="submit"
            class="ma-2 font-weight-bold text-button" dark color="primary")
            v-icon(dark) mdi-save
            span(class="font-weight-bold") 儲存
      
          v-btn(
            @click.stop="handleCancel"
            class="ma-2 ml-5 font-weight-bold text-button")
            span(class="font-weight-bold") 取消
</template>

<script>
import GroupComponent from "@/components/common/group";
export default {
  name: "outerDetailForm",
  components: {
    GroupComponent,
  },
  props: {
    detailMode: {
      type: String,
    },
    editItem: {
      type: Object,
    },
  },
  data() {
    return {
      valid: true,
      rules: [
        v => !!v || '必填',
      ],
      outerData: {
        church: null,
        gender: null,
        responsiblePerson: null,
        phone: null,
        email: null,
        tel: null,
        jobTitle: null,
        registrationNumber: null,
        registrationNameList: null,
        account5: null,
        remittanceDate: null,
        reservedSeat: null,
        uniformNo: null,
        uniformTitle: null,
      },
    };
  },
  watch: {
    editItem(val) {
      if (this.detailMode === 'edit') {
        this.outerData = val
      }
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.outerData.reservedSeat = this.outerData.reservedSeat ? 1 : 0
        this.$emit("submit", this.outerData);
      }
    },
    handleCancel() {
      this.outerData = {}
      this.$refs.form.reset();
      this.$emit("cancel");
    }
  },
  created() {
    if (this.detailMode === 'edit') {
      this.outerData = this.editItem
    }   
  }
};
</script>
