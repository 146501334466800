<template lang="pug">
v-container(fluid)
  div(class="d-flex justify-space-between mb-2")
    h2(class="blue-grey--text")
      v-icon(class="mr-1") mdi-home-variant-outline
      span 外堂繳費管理
  SearchForm(
    :loading="exporting"
    @add="handleOpenDetailDialog"
    @search="getList"
    @export="handleExport"
  )
  v-divider(class="my-4")
  h4(class="my-2") 查詢共 {{ listData.length }} 筆
  SearchTable(
    :loading="loading"
    :dataList="listData"
    @delete="handleDeleteItem"
    @edit="handleOpenDetailDialog"
    @sendSMS="handleOpenSendSMSDialog"
  )
  v-dialog(
    v-model="detailDialog"
    width="600"
    persistent
  )
    DetailForm(
      :detailMode="detailMode"
      :editItem="editItem"
      @cancel="handleCloseDetailDialog"
      @submit="handleSubmit"
    )
  v-dialog(
    v-model="sendSMSDialog"
    width="600"
    persistent
  )
    SMSForm(
      :editItem="editItem"
      :smsContent="smsContent"
      @cancel="handleCloseSendSMSDialog"
      @submit="handleSendSMS"
    )
</template>

<script>
import SearchForm from "@/components/outer/searchForm";
import SearchTable from "@/components/outer/searchTable";
import DetailForm from '@/components/outer/detailForm';
import SMSForm from '@/components/outer/smsForm';
export default {
  name: "outerSearch",
  components: {
    SearchForm,
    SearchTable,
    DetailForm,
    SMSForm
  },
  data: () => ({
    analyzeData: {},
    group: [],
    listData: [],
    loading: false,
    exporting: false,
    detailMode: null,
    detailDialog: false,
    editItem: {},
    sendSMSDialog: false,
    smsContent: ""
  }),
  methods: {
    async getClcList() {
      try {
        let clcListRes = await this.$api.common.commonAPI.getClcList();
        if (clcListRes && clcListRes.data.code === 200) {
          this.$store.dispatch("group/setClcList", clcListRes.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getList(payload) {
      try {
        this.loading = true;
        let listRes = await this.$api.outer.outerAPI.getList(
          payload
        );
        if (listRes && listRes.data.code === 200) {
          this.listData = listRes.data.content;
        }
        if (!listRes.data.success && listRes.data.showalert) {
          this.$store.dispatch("notification/openNotification", {
            msg: listRes.data.message,
            color: "red",
            timeout: 5000,
            showClose: false,
          });
          if (listRes.data.isRedirect) {
            this.$router.push("/permissions");
          }
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async handleExport(searchPayload) {
      try {
        this.exporting = true;
        let res = await this.$api.outer.outerAPI.exportList(searchPayload);
        const blob = new Blob([res.data], { type: res.headers['content-type'] })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.click()
        link.remove()
        this.exporting = false;
        this.$store.dispatch("notification/openNotification", {
          msg: `匯出成功`,
          color: "green",
          timeout: 5000,
          showClose: false,
        });
      } catch (err) {
        console.log(err);
      }
    },
    handleOpenDetailDialog (item) {
      this.detailMode = item ? 'edit' : 'add'
      this.detailDialog = true
      this.editItem = item
    },
    handleCloseDetailDialog () {
      this.detailDialog = false
      this.editItem = {}
    },
    handleSubmit (item) {
      if (this.detailMode === 'add') {
        this.handleAddItem(item)
      }
      if (this.detailMode === 'edit') {
        this.handleUpdate(item)
      }
    },
    async handleUpdate(item) {
      try {
        await this.$api.outer.outerAPI.modifyOuter(item);
        this.$store.dispatch("notification/openNotification", {
          msg: `資料已更新`,
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.detailMode = null;
        this.detailDialog = false
        this.getList({responsiblePerson: "", phone: ""});
      } catch (err) {
        console.log(err);
      }
    },

    async handleAddItem(item) {
      try {
        await this.$api.outer.outerAPI.addOuter(item);
        this.$store.dispatch("notification/openNotification", {
          msg: `資料已新增`,
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.detailDialog = false
        this.detailMode = null;
        this.getList({responsiblePerson: "", phone: ""});
      } catch (err) {
        console.log(err);
      }
    },

    async handleDeleteItem(item) {
      try {
        await this.$api.outer.outerAPI.deleteOuter({
          id: item.id,
        });
        this.$store.dispatch("notification/openNotification", {
          msg: "已刪除",
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.getList({responsiblePerson: "", phone: ""});
      } catch (err) {
        console.log(err);
      }
    },

    handleOpenSendSMSDialog(item) {
      this.editItem = item
      this.sendSMSDialog = true
    },
    handleCloseSendSMSDialog () {
      this.sendSMSDialog = false
      this.editItem = {}
    },
    async getSMSContent() {
      try {
        let res = await this.$api.outer.outerAPI.getSMS();
        this.smsContent = res.data.content.smsContent
      } catch (err) {
        console.log(err);
      }
    },
    async handleSendSMS (item) {
      try {
        await this.$api.outer.outerAPI.sendMessage(item);
        this.$store.dispatch("notification/openNotification", {
          msg: `簡訊已寄送`,
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.sendSMSDialog = false
        this.getList({responsiblePerson: "", phone: ""});
      } catch (err) {
        console.log(err);
      }
    }
  },
  created() {
    this.getClcList();
    this.getList({responsiblePerson: "", phone: ""});
    this.getSMSContent();
  },
};
</script>
