<template lang="pug">
v-row
  v-col(cols="12" md="4")
    v-select(
      v-model="groupType" 
      :items="groupTypeOptions"
      label="組別"
      hide-details
    )
  v-col(cols="6" md="4")
    v-select(
      v-model="districtName" 
      :items="districtOptions"
      :disabled="groupType === ''"
      label="牧區"
      hide-details
    )
  v-col(cols="6" md="4")
    v-select(
      v-model="groupId" 
      :items="groupOptions"
      item-text="groupName"
      item-value="groupId"
      :disabled="districtName === ''"
      label="小組"
      hide-details
    )
</template>

<script>
export default {
  name: "Group",
  props: ["searchGroupId"],
  data: () => ({
    groupType: "",
    districtName: "",
    groupId: "",
  }),
  watch: {
    groupType(oldVal, newVal) {
      if (newVal && oldVal !== newVal) {
        this.districtName = "";
        this.groupId = "";
      }
    },
    districtName(val) {
      if (val === "") this.groupId = "";
    },
    searchGroupId(val) {
      if (val === null) {
        this.groupType = "";
        this.groupId = val;
      }
    },
    groupId(val) {
      this.$emit("groupId", val);
    },
  },
  computed: {
    groupTypeOptions() {
      return this.$store.state.group.groupType;
    },
    groups() {
      return this.$store.state.group.group;
    },
    districtOptions() {
      const groupMap = this.groups
        .filter((group) => group.group_type_name === this.groupType)
        .reduce((acc, g) => {
          acc[g.district_name] = true;
          return acc;
        }, {});
      return Object.keys(groupMap).map((g) => {
        return g;
      });
    },
    groupOptions() {
      return this.groups
        .filter((g) => g.district_name === this.districtName)
        .map((g) => ({ groupName: g.group_name, groupId: g.group_id }));
    },
  },
  created () {
    this.groupId = this.searchGroupId
    let selectedGroup = this.groups.find((item) => { return item.group_id === this.groupId })
    this.groupType = selectedGroup ? selectedGroup.group_type_name : ''
    this.districtName = selectedGroup ? selectedGroup.district_name : ''
  }   
};
</script>
