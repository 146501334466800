<template lang="pug">
v-card(class="px-5 pb-5")
  v-card-title(class="px-0")
    span(class="text-h5 font-weight-bold") 寄發簡訊
  v-form(@submit.prevent="submit" 
    ref="form"
    v-model="valid"
    lazy-validation)
    v-row
      v-col(cols="6")
        v-text-field(
          v-model="smsData.responsiblePerson" 
          label="負責人"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="smsData.phone" 
          label="手機"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="smsData.email" 
          label="Email"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="smsData.feeText" 
          label="已繳款費用"
          hide-details
        )
      v-col(cols="12")
        v-textarea(
          v-model="smsData.smbody" 
          label="簡訊內容"
          hide-details
          rows="5"
        )
    v-row
      v-col(class="d-flex justify-center")
          v-btn(
            type="submit"
            class="ma-2 font-weight-bold text-button" dark color="primary")
            v-icon(dark) mdi-save
            span(class="font-weight-bold") 確認寄發
      
          v-btn(
            @click.stop="handleCancel"
            class="ma-2 ml-5 font-weight-bold text-button")
            span(class="font-weight-bold") 取消
</template>

<script>
import GroupComponent from "@/components/common/group";
export default {
  name: "outerSMSForm",
  components: {
    GroupComponent,
  },
  props: {
    editItem: {
      type: Object,
    },
    smsContent: {
      type: String
    }
  },
  data() {
    return {
      valid: true,
      rules: [
        v => !!v || '必填',
      ],
      smsData: {
        responsiblePerson: null,
        phone: null,
        email: null,
        feeText: null,
        smbody: null,
      },
    };
  },
  watch: {
    editItem(val) {
      this.smsData.id = val.id
      this.smsData.responsiblePerson = val.responsiblePerson
      this.smsData.phone = val.phone
      this.smsData.email = val.email
      this.smsData.feeText = val.amount
      this.smsData.smbody = this.smsContent
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.smsData);
      }
    },
    handleCancel() {
      this.$emit("cancel");
    }
  },
  created() {
    this.smsData.id = this.editItem.id
    this.smsData.responsiblePerson = this.editItem.responsiblePerson
    this.smsData.phone = this.editItem.phone
    this.smsData.email = this.editItem.email
    this.smsData.feeText = this.editItem.amount
    this.smsData.smbody = this.smsContent
  },
};
</script>
